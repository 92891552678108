import React, { createContext, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import About from "./pages/About/About";
import Home from "./pages/Home/Home";
import Work from "./pages/Work/Work";
import Contact from "./pages/Contact/Contact";
import Skills from "./pages/Skills/Skills";
import Certifications from "./pages/Certifications/Certifications";
import "./App.css";
import Header from "./components/js/Header";
import useFirebase from "../src/hooks/use-firebase";

export const ThemeContext = createContext(null);

const App = () => {
  const [theme, setTheme] = useState("light");
  const {
    projects,
    certifications,
    isLoadingProjects,
    isLoadingCertifications,
  } = useFirebase();

  const toggleTheme = () => {
    setTheme((curr) => (curr === "light" ? "dark" : "light"));
  };

  return (
    <Router>
      <ThemeContext.Provider value={{ theme, toggleTheme }}>
        <main className="App" id={theme}>
          <Header toggleTheme={toggleTheme} theme={theme} />
          <Switch>
            <Route path={"/"} exact>
              <Home theme={theme} />
            </Route>
            <Route path={"/about"}>
              <About />
            </Route>
            <Route path={"/work"}>
              <Work projects={projects} loading={isLoadingProjects} />
            </Route>
            <Route path={"/contact"}>
              <Contact />
            </Route>
            <Route path={"/skills"}>
              <Skills />
            </Route>
            <Route path={"/certifications"}>
              <Certifications
                certifications={certifications}
                loading={isLoadingCertifications}
              />
            </Route>
            <Redirect to={"/"} />
          </Switch>
          <footer class="footer bg-link fade-in text-center text-white">
            <div className="container p-1"></div>
            <div class="text-center">
              <p>Handcrafted by Ștefan Juca &copy; 2023</p>
            </div>
          </footer>
        </main>
      </ThemeContext.Provider>
    </Router>
  );
};

export default App;
